window.onload = function () {
    if (this.readyToLoadReport) {
        setBasicDom();
        retrieveReport();
        setFavIcon();
        setTitle();
        setLogo();
        setBanner();
        setLocationSearch();
        createTabs();
        listenFirstTab();
        openFirstTab();
        setFooter();
        createCookieLabel();
        setCookieLabel();
        setCookieParams();
        updateUrlParams();
        checkTabDivHeight();
    }
};

// Retrive report data
var reports = null;
const retrieveReport = () => {
    fetch(appConfig.reportToken)
        .then(res => res.json())
        .then((data) => {
            reports = data;
    }).catch(err => console.error(err));
};


//set up basic DOM structure
const setBasicDom = () => {
    var headerDiv = document.createElement('DIV');
    headerDiv.id = "header";
    document.body.appendChild(headerDiv);

    var tabDiv = document.createElement('DIV');
    tabDiv.id = 'tabDiv';
    tabDiv.className = 'tab';
    tabDiv.innerHTML =  '<img id="pageLogo" class="pageLogo">' +
                        '<button id="tabButton_Home" class="tabButton">Home</button>' +
                        '<a id="menu-toggle">Menu</a>' +
                        '<div id="locationBox" class="locationBox">' +
                            '<img src="'+appConfig.locationIcon+'"/>' +
                            '<span id="locationLabel"></span>' +
                        '</div>';
    headerDiv.appendChild(tabDiv);

    if(!checkLocationLabelValue()) {
        var enableCookie = localStorage.getItem('enableCookie');
        var postcode = localStorage.getItem('postcode');
        if ( appConfig.enableCookie == true && enableCookie == "true" && typeof(postcode) !== undefined && postcode !== null && postcode !== '') {
            document.getElementById('locationLabel').innerHTML = localStorage.getItem("postcode");
        } else {
            document.getElementById('locationLabel').innerHTML = "Search location";
        }
    }

    var menuToggle = document.getElementById('menu-toggle');
    menuToggle.addEventListener('click', function(){
        // show and hide menu items in mobile
        var tabButtons = document.getElementsByClassName('tabButton');
        var i;

        for (i = 0; i < tabButtons.length; i++) {
            if (tabButtons[i].style.display == 'block') {
                tabButtons[i].style.display = 'none';
            } else {
                tabButtons[i].style.display = 'block';
            }      
        }
    });

    var locationLabel = document.getElementById('locationBox');
    locationLabel.addEventListener('click', function(){
            //active locationSearch and inactive tabDiv
            document.getElementById('locationSearch').style.display = "block";
            var tabBodys =  document.getElementsByClassName('tabBody');
            for(var k = 0; k < tabBodys.length; k++) {
                tabBodys[k].style.display = "none";
            }
    });


    var tabDiv_Home = document.createElement('DIV');
    tabDiv_Home.id = 'tabDiv_Home';
    tabDiv_Home.className = 'tabBody';
    tabDiv_Home.innerHTML =     '<div id="banner" class="banner"></div>' +
                                '<table class="tileTable"><tr id="homeTilesTableRow"></tr><tr id="homeTilesTableRow01"></tr></table>';
    document.body.appendChild(tabDiv_Home);

    var locationSearch = document.createElement('DIV');
    locationSearch.id = 'locationSearch';
    locationSearch.className = 'locationSearch';
    document.body.appendChild(locationSearch);
};

const setFavIcon = () => {
    var link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = appConfig.icon;
    document.getElementsByTagName('head')[0].appendChild(link);
};

const setTitle = () => {
    document.title = appConfig.title;
};


const setLogo = () => {
    var logo = document.getElementById('pageLogo').src = appConfig.logo;
};

//setBanner detail
const setBanner = () => {
    var banner = document.getElementById('banner');
    banner.innerHTML =  '<p class="bannerTitle">' +string.homepage.bannerTitle+  '</p>' +
                        '<p class="bannerContent">' +string.homepage.bannerContent1+ '<br>' +string.homepage.bannerContent2+ '</p>';

    if ( appConfig.bannerPlayButton ) {
        banner.className += " bannerWithButton";
        banner.innerHTML += '<button class="bannerButton">' +
                                '<div class="playButton"></div>'
                                    +string.homepage.playButtonText+
                            '</button>';
    }
};

//set location search detail
const setLocationSearch = () => {
    var locationSearch = document.getElementById ('locationSearch');
    locationSearch.innerHTML =  '<p class="searchTitle">' +string.searchDiv.searchTitle+  '</p>' +
                                '<p class="searchDescription">' +string.searchDiv.searchDescription+ '</p>' +
                                '<div id="searchForm">' +
                                    '<input type="text" id="searchContent" class="searchContent" name="postcode" value="" autocomplete="off" placeholder="'+string.searchDiv.searchPlaceholder+'"></input>' +
                                    '<button id="searchSubmit" class="searchSubmit"> '+string.searchDiv.searchSubmit+' </button>' +
                                '</div>'+
                                '<div id="searchMessage" class="searchMessage"></div>';
    var searchSubmit = document.getElementById('searchSubmit');
    searchSubmit.addEventListener('click', getGeoData);
    var searchInput = document.getElementById('searchContent');
    searchInput.addEventListener('focus', function(){
        var searchMessage = document.getElementById('searchMessage');
        searchMessage.style.display = 'none';
        searchMessage.className = "searchMessage";
    });
    searchInput.addEventListener('keyup', function(event) {
        if(event.keyCode === 13) {
            searchSubmit.click();
        }
    });
};

// set Power BI reports
const setReport = (name) => {
    var tabBody = document.getElementById('tabDiv_'+ name);
    var nameRegex = new RegExp('\\b' + name + '?\\b');
    var locationLabel = document.getElementById('locationLabel');
    
    // get report that matches selected tab's name
    var report =  reports.filter(function(item) {
        return nameRegex.test(item.report_name);
    });

    // get config object for the selected report
    var reportConfig = appConfig.pages.filter(function(item) {
        return nameRegex.test(item.pageName);
    });

    // Read embed application token from textbox
    var txtAccessToken = report[0].access_token;

    // Read embed URL from textbox
    var txtEmbedUrl = report[0].embedUrl;

    // Read report Id from textbox
    var txtEmbedReportId = report[0].report_id;

    // Read embed type from radio
    var tokenType = 1;

    // Check screen size and set layout type
    var layoutType = 0;
    
    if ( window.innerWidth < window.innerHeight & window.innerWidth < 800 ) {
        //for portrait view
        layoutType = 2;
    }
    
    if ( window.innerWidth > window.innerHeight & window.innerWidth < 1099 ) {
        // for landscape view
        layoutType = 3;
    }

    // Embed configuration used to describe the what and how to embed.
    var pbiConfig = {
        type: 'report',
        tokenType: tokenType,
        accessToken: txtAccessToken,
        embedUrl: txtEmbedUrl,
        id: txtEmbedReportId,
        settings: {
            panes: {
                filters: {
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            },
            layoutType: layoutType
        }
    };

    // Get a reference to the embedded report HTML element
    var embedContainer = tabBody;

    // Embed the report and display it within the div container.
    var pbi = powerbi.embed(embedContainer, pbiConfig);

    // If location is required, filter the report with postcode
    if (reportConfig[0].locationRequired == true) {
            var postCode = localStorage.getItem('postcode') ? localStorage.getItem('postcode') : locationLabel.innerHTML;

            var postcodeFilter = {
                $schema: "http://powerbi.com/product/schema#basic",
                filterType: 1,
                target: {
                    table: "Postcode",
                    column: "Postcode"
                },
                operator: "In",
                requireSingleSelection: false,
                values: [postCode]
            };
            
            // Apply filter
            setTimeout(function() {
                pbi.setFilters([postcodeFilter])
                    .catch(function(errors) {
                        // Handle error
                        console.log(errors);
                    });
            }, 3000);
    }

    // Report.off removes a given event handler if it exists.
    pbi.off("loaded");

    // Report.off removes a given event handler if it exists.
    pbi.off("rendered");

    pbi.on("error", function (event) {
        console.log(event.detail);

        pbi.off("error");
    });

    pbi.off("saved");
    pbi.on("saved", function (event) {
        console.log(event.detail);
        if (event.detail.saveAs) {
            console.logText('In order to interact with the new report, create a new token and load the new report');
        }
    });

    tabBody.style.display = "block";
};

//check location search parameters
const checkLocationLabelValue = () => {
    var checkResult = false;
    var locationLabel = document.getElementById('locationLabel');
    if(locationLabel.innerHTML != "Search location" && locationLabel.innerHTML != "") {
        checkResult = true;
    }
    return checkResult;
};

//get lan & lng from  Geocoding
const getGeoData = () => {
    var locationSearch = document.getElementById ('locationSearch');
    var searchPostcode = document.getElementById('searchContent');
    var searchMessage = document.getElementById('searchMessage');
    var geoParams = mapAPIConfig.googleMapApi.geoParams;
    var url = mapAPIConfig.googleMapApi.url + 'components=' + 'country:'+appConfig.country+ '|' + 'postal_code:'+searchPostcode.value+'&key=' +mapAPIConfig.googleMapApi.apiAccessKey+ '';
    if(searchPostcode.value == "") {
        searchMessage.style.display = "block";
        searchMessage.innerHTML = " Please type your postcode ";
        searchMessage.className += " warning";
    } else {

    fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((geodata) => {
        if (geodata.status === 'OK' && geodata.results.length == 1) {
            //  remove current localStorage
            for (var i = 0; i < geoParams.length; i++) {
                localStorage.removeItem(geoParams[i]);
            }
            // set new localStorage
            if (typeof (appConfig.enableCookie) !== 'undefined' && appConfig.enableCookie == true) {
                var enableCookieParam = localStorage.getItem('enableCookie');
                if (typeof (enableCookieParam) !== 'undefined' && enableCookieParam == "true") {
                    localStorage.setItem('country', appConfig.country);   //get from customer parameter
                    localStorage.setItem('postcode', geodata.results[0].address_components[0].short_name); //get from input
                    localStorage.setItem('lat', geodata.results[0].geometry.location.lat);
                    localStorage.setItem('lng', geodata.results[0].geometry.location.lng);
                }
            }
            //loading new Label parameter
            var locationLabel = document.getElementById('locationLabel');
            locationLabel.innerHTML = searchPostcode.value.toUpperCase();

            //close loaction search and active tabDiv
            var pageName;
            locationSearch.style.display = "none";
            const elements = document.getElementsByClassName('tabButton');
            for(var j = 0; j < elements.length; j++) {
               if(elements[j].classList.contains("active")) {
                   pageName = elements[j].innerHTML;
               }
            }

            if (pageName !== 'Home') {
                setReport(pageName);
            }

            document.getElementById('tabDiv_' + pageName).style.display = "block";

        } else {
            searchMessage.style.display = "block";
            searchMessage.innerHTML = " We couldn't find your postcode ";
            searchMessage.className += " error";
        }
    });
}
};


const createTabs = () => {
    var tabsDiv = document.getElementById('tabDiv');
    var homeTilesTableRow = document.getElementById('homeTilesTableRow');
    var homeTilesTableRow01 = document.getElementById('homeTilesTableRow01');
    var locationBox = document.getElementById('locationBox');
    for (var i = 0; i<appConfig.pages.length; i++) {
        var pageName = appConfig.pages[i].pageName;
        // Create the tab button.
        var tabButton = document.createElement("BUTTON");
        tabButton.id = 'tabButton_'+pageName;
        tabButton.className = 'tabButton';
        tabButton.innerHTML = pageName;
        tabButton.addEventListener('click', openTab);
        tabsDiv.appendChild(tabButton);
        if(appConfig.pages[i].locationRequired) {
            locationBox.style.display =  'block';
            tabButton.className += ' requireLocation';
        }

        // Create the tab.
        var tabDiv = document.createElement("DIV");
        tabDiv.id = 'tabDiv_'+pageName;
        tabDiv.className = 'tabBody';
        var tabIFrame = document.createElement("IFRAME");

        tabDiv.appendChild(tabIFrame);
        document.body.appendChild(tabDiv);


        // Create the home tiles.
        var homeTileCell = document.createElement('TD');
        homeTileCell.className = "homeTileCell";
        homeTileCell.id = 'homeTileCell_'+pageName;
            if(i<3) {
                homeTileCell.innerHTML =
                                        '<div class="homeTileHeader">'+
                                            '<p class="homeTileTitle">'+appConfig.pages[i].homeTileTitle+'</p>'+ 
                                        '</div>'+
                                            '<p class="homeTileImage">'+ '<img src ="'+appConfig.pages[i].homeTileImage+'"/>' + '</p>'+ 
                                            '<p class="homeTileDescription">'+appConfig.pages[i].homeTileDescription+'</p>'+
                                            '<p class="homeTileViewReport">'+string.homepage.viewMonthlyReport+'</p>';
                homeTileCell.addEventListener('click', openTab);
                homeTilesTableRow.appendChild(homeTileCell);
            }

            //Create new row if homeTiles more than 3
            if(i>2) {
                homeTileCell.innerHTML =
                                        '<div class="homeTileHeader">'+
                                            '<p class="homeTileTitle">'+appConfig.pages[i].homeTileTitle+'</p>'+ 
                                        '</div>'+
                                        '<p class="homeTileImage">'+ '<img src ="'+appConfig.pages[i].homeTileImage+'"/>' + '</p>'+ 
                                            '<p class="homeTileDescription">'+appConfig.pages[i].homeTileDescription+'</p>'+
                                            '<p class="homeTileViewReport">'+string.homepage.viewMonthlyReport+'</p>';
                homeTileCell.addEventListener('click', openTab);
                homeTilesTableRow01.appendChild(homeTileCell);
            }
            if(appConfig.pages[i].locationRequired) {
                locationBox.style.display =  'block';
                homeTileCell.className += ' requireLocation';
            }

            setTimeout(function() {
                matchHeight(".homeTileTitle");
                matchHeight(".homeTileDescription");
            }, 1000);   
    }
};

// check the height of tabDiv_Home
const checkTabDivHeight =() => {
    var tabDiv_Home = document.getElementById('tabDiv_Home');
    var tabHeight = tabDiv_Home.offsetHeight;
    var screenHeight = window.innerHeight;
    if((screenHeight - tabHeight) < 153) {
        tabDiv_Home.className += ' lowHeight';
    }
};

//set footer
const setFooter = () => {
    var footerBox = document.createElement('DIV');
    footerBox.className = "footer";
    footerBox.innerHTML =   '<div class="footerLink">' +
                                '<span>'+string.homepage.copyright+'</span>' +
                                '<a href="'+appConfig.privacyLink+'">'+string.homepage.privacy+'</a>' +
                                '<a href="'+appConfig.contactLink+'">'+string.homepage.contact+'</a>' +
                                '<div class="footerLogo">' +
                                    '<span class="insightFull">'+string.homepage.insightFullCore+'</span>' +
                                    '<span class="powerBy">'+string.homepage.poweredBy+'</span>' +
                                    '<img src ="'+appConfig.EMSBKLogo+'" />' +
                                '</div>'+
                            '</div>';
     document.body.appendChild(footerBox);
};

//set cookie Label
const createCookieLabel = () => {
    var cookieLabel = document.createElement('DIV');
    cookieLabel.className = "cookieLabel";
    cookieLabel.id = "cookieLabel";
    cookieLabel.innerHTML =     '<span>'+string.homepage.cookieNotification+'</span>' +
                                '<button id="buttonReject">Reject</button>' +
                                '<button id="buttonAccept">Accept</button>';
     document.body.appendChild(cookieLabel);
};

//set cookie params
const setCookieLabel = () => {
    if(typeof(appConfig.enableCookie) !== 'undefined' && appConfig.enableCookie == true) {
        var enableCookieParam = localStorage.getItem('enableCookie');
        if(typeof(enableCookieParam) !== 'undefined' && enableCookieParam == null || '') {
            document.getElementById("cookieLabel").style.display = 'block';
        }
    } else {
        return;
    }
};

//set cookie local storage
const setCookieParams = () => {
    var buttonAccept = document.getElementById('buttonAccept');
    var buttonReject = document.getElementById('buttonReject');
    buttonAccept.addEventListener('click', function(){
        localStorage.setItem('enableCookie', true);
        document.getElementById("cookieLabel").style.display = 'none';
    });
    buttonReject.addEventListener('click', function(){
        localStorage.setItem('enableCookie', false);
        document.getElementById("cookieLabel").style.display = 'none';
    });
};

const listenFirstTab = () => {
    document.getElementsByClassName("tabButton")[0].addEventListener('click', openTab);
};


const openFirstTab = () => {
    document.getElementsByClassName("tabButton")[0].click();
};

const updateUrlParams = () => {
    //get postcode params 
    var postcode = document.getElementById('locationLabel').innerHTML;
    for (var i = 0; i<appConfig.pages.length; i++) {
        var pageUrl = appConfig.pages[i].pageUrl;
        var vars = {};
        var parts = pageUrl.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        if(Object.keys(vars).includes('Postcode') && postcode !== null || '') {
            //update Iframe src
            var pageName = appConfig.pages[i].pageName;
            var tabIFrame = document.getElementById('tabDiv_'+pageName).firstChild;
            tabIFrame.src = appConfig.pages[i].pageUrl + postcode;
        }
    }
};

export const openTab = (evt) => {
    // Inactivate all tabs.
    var tabButtons = document.getElementsByClassName("tabButton");
    var tabBodies = document.getElementsByClassName("tabBody");
    var locationSearch = document.getElementById('locationSearch');
    var pageId = evt.currentTarget.id;
    var pageClass =  evt.currentTarget.className; 
    var pageName = pageId.split("_")[1];

    for (var i = 0; i < tabButtons.length; i++) {
        tabButtons[i].className = tabButtons[i].className.replace(" active", "");
        tabBodies[i].style.display = "none";
    }

    document.getElementById('tabButton_'+pageName).className += " active";

    if ((pageClass.includes('requireLocation') && checkLocationLabelValue()) ||
        !pageClass.includes('requireLocation')) {
        
        locationSearch.style.display = "none";
      
        if (pageName !== 'Home') {
            setReport(pageName);
        }
          
        document.getElementById('tabDiv_'+pageName).style.display = "block";
    } else {
        locationSearch.style.display =  'block';
    }

};

// check if data is empty
const is_empty = (data) => {
    var count = 0, i;

    if (typeof data === 'number') {
        return false;
    }

    if (typeof data === 'boolean') {
        return !data;
    }

    if (data === undefined || data === null) {
        return true;
    }

    if (data.length !== undefined) {
        return data.length === 0;
    }

    for (i in data) {
        if (data[i]) {
            count += 1;
        }
    }

    return count === 0;
};

// match height of same elements
const matchHeight = (selector) => {
    try {
        var elements = document.querySelectorAll(selector),
        max = 0,
        i = 0;

        if (is_empty(elements)) {
            throw "No matched selector";
        }

        for (i = 0; i < elements.length; i++) {
            if (elements[i]) {         
                if (elements[i].offsetHeight > max) {
                    max = elements[i].offsetHeight;
                }
            }
        }

        for (i = 0; i < elements.length; i++) {
            if (elements[i]) {
                if (elements[i].offsetHeight < max) {
                    elements[i].style.height = max + "px";
                }
            }
        }
    } catch (e) {
        window.console.error("Match Height: " + e.message);
    }
};

// Trigger matchHeight function when window resizes
window.onresize = function(event) {
    matchHeight(".homeTileTitle");
    matchHeight(".homeTileDescription");
};